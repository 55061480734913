@tailwind base;
@tailwind components;
@tailwind utilities;

@import './app/styles/variables.css';
@import './app/styles//global.css';
@import './app/styles//button.css';
@import './app/styles/fonts.css';

html, body {
  background-color: var(--blue-dark);
  background-size: cover;
  background-image: url('./assets/img/background.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  scroll-behavior: smooth;
}

body {
  width: 100%;
}

* {
  font-family: 'CircularXX', sans-serif;
  font-weight: 400;
}

h2 {
  font-weight: 600;
  line-height: 0.8em;
  text-align: center;
}

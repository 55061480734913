/* 
    Available font-weights: 
        regular:     400;
        medium:      500;
        semi-bold:   600;
        bold:        700;
*/

@font-face {
    font-family: 'CircularXX';
    src: url('/assets/fonts/CircularXX-Regular.woff') format('woff');
    font-display: swap;
    font-weight: 400;
  }
  
@font-face {
    font-family: 'CircularXX';
    src: url('/assets/fonts/CircularXX-Medium.woff') format('woff');
    font-display: swap;
    font-weight: 500;
}
  
@font-face {
    font-family: 'CircularXX';
    src: url('/assets/fonts/CircularXX-Bold.woff') format('woff');
    font-display: swap;
    font-weight: 600;
}
  
@font-face {
    font-family: 'CircularXX';
    src: url('/assets/fonts/CircularXX-ExtraBlack.woff') format('woff');
    font-display: swap;
    font-weight: 700;
}

@font-face {
    font-family: 'CircularXX';
    src: url('/assets/fonts/CircularXX-ExtraBlack.woff') format('woff');
    font-display: swap;
    font-weight: 700;
}

@font-face {
    font-family: 'CircularXX';
    src: url('/assets/fonts/CircularXX-ExtraBlack.woff') format('woff');
    font-display: swap;
    font-weight: 700;
}

button.disabled {
    background-color: grey;
    color: white;
    border: 1px solid grey;
}

button.disabled:hover {
    background-color: grey;
    color: white;
    border: 1px solid grey;
}
